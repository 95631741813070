import { ThemeConfig } from 'antd/es/config-provider/context'

import colors from '../colors.module.scss'

export const token: ThemeConfig['token'] = {
  colorPrimary: colors.colorPrimary,
  colorTextSecondary: colors.primaryTextColor,
  colorText: colors.text,

  borderRadius: 8,
}
