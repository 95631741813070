import { Typography } from 'antd'
import classNames from 'classnames/bind'
import { motion } from 'framer-motion'
import mainDictionary from '@/dictionary'
import { Form } from './Form'

import styles from './login.module.scss'

const cn = classNames.bind(styles)

const { Title } = Typography

const loginMotion = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
}

const item = {
  hidden: { y: -40, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

export const Login = () => (
  <div className={cn('login')}>
    <motion.div variants={loginMotion} initial="hidden" animate="visible">
      <motion.div variants={item} className={cn('login__title')}>
        <Title level={2}>{mainDictionary.loginPageTitle}</Title>
      </motion.div>

      <Form />
    </motion.div>
  </div>
)
