import { ThemeConfig } from 'antd/es/config-provider/context'

import colors from '../colors.module.scss'

export const components: ThemeConfig['components'] = {
  Typography: {},
  Layout: {
    headerBg: colors.white,
    siderBg: colors.sideBg,
    triggerBg: colors.sideBg,
    bodyBg: colors.white,
  },
  Menu: {
    itemBg: colors.sideBg,
    itemBorderRadius: 8,
    itemMarginInline: 12,
    itemPaddingInline: 10,
  },
  Button: {
    dangerColor: colors.red,
  },
}
