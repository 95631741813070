import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { SearchOutlined } from '@ant-design/icons'
import { Badge, Button, Flex, Layout as AntdLayout, Input } from 'antd'
import classnamesBind from 'classnames/bind'
import BellIcon from '@/assets/icons/bell.svg?react'
import { ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { useStores } from '@/stores'
import { useDeviceMediaQuery } from '@/utils'
import { Notification } from '../../Notification'
import { Drawer } from '../Drawer'

import styles from './header.module.scss'

const cn = classnamesBind.bind(styles)

const INTERVAL_DELAY = 5_000

export const Header = observer(() => {
  const { appStore, kinescopeStore, breadcrumbStore } = useStores()
  const [hasUploadingFiles, setHasUploadingFiles] = useState(false)
  const { isMobile } = useDeviceMediaQuery()
  const navigate = useNavigate()
  const [uploadingFilesLinks, setUploadingFilesLinks] = useState(kinescopeStore.getUploadingFilesLinks())
  const menuItems = useMemo(
    () =>
      uploadingFilesLinks.map((page) => ({
        key: page.link!,
        label: <Link to={page.link!}>{page.name || page.link}</Link>,
      })),
    [uploadingFilesLinks?.length],
  )

  const handleBadgeClick = () => {
    navigate(ROUTES.myNotification)
  }

  useEffect(() => {
    const id = window.setInterval(() => {
      const hasUploading = kinescopeStore.hasUploadingFiles()

      setHasUploadingFiles(hasUploading)

      if (hasUploading) {
        setUploadingFilesLinks(kinescopeStore.getUploadingFilesLinks())
      }
    }, INTERVAL_DELAY)

    return () => {
      clearInterval(id)
    }
  }, [])

  return (
    <AntdLayout.Header className={cn('header__wrapper')}>
      <Flex justify="space-between" align="center" gap="middle" className={cn('header')}>
        <div className={cn('header__left')}>
          {isMobile && <Drawer />}

          <Flex gap="8px" align='center'>
          <SearchOutlined />
          <Input variant='borderless' placeholder={mainDictionary.search} />
          </Flex>
        </div>

        <Flex gap="middle">
          <Badge overflowCount={9} count={appStore.notificationsCount}>
            <Button icon={<BellIcon />} type="text" onClick={handleBadgeClick} size="large" />
          </Badge>

          {hasUploadingFiles && (
            <Notification hasUploadingFiles={hasUploadingFiles} dropdownMenu={{ items: menuItems }} />
          )}
        </Flex>
      </Flex>
    </AntdLayout.Header>
  )
})
